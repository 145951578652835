
import { _ } from "vue-underscore";
export default {
	data: () => ({
		company: {
			id: 0,
			name: "",
			slug: "",
		},
		loading: false,
		error: "",
		backUrl: "/company"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.company = {
				id: 0,
				name: "",
			};
		},
		validateCompany() {
			this.company.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			const companyData = {
				name: this.company.name,
			};
			// const slug = this.company.slug;
			//   const companyDat = JSON.stringify(companyData);
			// fd.append("_method", "PATCH");
			// slug,
			this.axios
				.post("/company/" + this.company.id, {
					company: companyData,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.company = response.data.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			const companyData = {
				name: this.company.name,
			};
			// const slug = this.company.slug;
			//   const companyDat = JSON.stringify(companyData);
			this.axios
				.post("/company/", { company: companyData})
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("company/" + this.$route.params.id)
				.then(function (response) {
					_vm.company = response.data.data;
					_vm.company.name = _vm.company.company.name
				})
				.catch(function () { });
		},
		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		selectImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.company.logoImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview").hide();
					$("#imagePreview").fadeIn(650);
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
